import React from 'react';
import LogoSrc from './images/logo/iEC professional logo.png';
import { connect } from 'react-redux';

const Footer = (props) => {
	let copyright = "iEC Professional Pty Ltd";
	if (props.tenantHasCopyright) {
		copyright = props.tenantTitle;
	}
	return (
		<footer style={{ marginBottom: 0, textAlign: 'center' }} role="contentinfo">
			{props.showPoweredBy && <span>Powered by</span>}
			<img src={props.tenantHasCopyright ? (props.logo || LogoSrc) : LogoSrc} height="50" className="logo" alt="" style={{ padding: '10px' }} />
			<span>© 2023 {copyright}. All rights reserved.</span>
		</footer>
	);
};

const mapStateToProps = state => ({
	showPoweredBy: state.settings.showPoweredBy
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);