import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { searchInsights, isLoading } from '../../store/insights';
import { canSubmitInsights, canMakePublic, hasFunction } from '../../store/security';
import { getQueryParameters } from '../../utils/utils';
import { withForm } from '../../utils/forms';
import Button from '../widgets/Button';
// import ButtonLink from '../widgets/ButtonLink';
import PageLayout from '../PageLayout';
import InsightCard from './InsightCard';
import pick from 'lodash/pick';
import { Redirect } from 'react-router-dom';
import map from 'lodash/map';
import PagePagination from '../widgets/PagePagination';
import MakePublicButton from '../widgets/MakePublicButton';

let timer = null;
const form = {
	initValues: props => props.searchArgs,
	onChange: (props, values, name) => {
		clearTimeout(timer);
		timer = setTimeout(() => props.searchInsights(values), name === "search" ? 500 : 0);
	},
	fields: [
		{
			name: "search",
			placeholder: "Search",
			startAdornment: props => props.loading ? <CircularProgress size={20} style={{ marginRight: 4 }} /> : <SearchIcon />
		},
		{
			name: "channel",
			label: "Filter by channel",
			type: "autoComplete",
			loadItems: { 
				route: props => `channels?subscribedOnly=${props.isCommunity}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		},
		{
			name: "contentType",
			label: "Filter by Publication Type",
			type: "select",
			items: [
				{ label: "(All)", value: "all" }, 
				{ label: "Academic Authored", value: "Academic" }, 
				{ label: "Industry Authored", value: "Industry" },
				{ label: "Market Insight", value: "MarketInsight" }
			]
		}, 
		{
			name: "tag",
			label: "Filter by tag",
			type: "autoComplete",
			loadItems: { 
				route: (props, values) => `tags/insights?visibility=${values.visibility}`, 
				mapItem: "name" 
			},
			defaultItem: { label: "(All)", value: "all" }
		}
	]
};

const InsightsController = props => {
	const { insights, fields } = props;
	
	if (props.organisationType === 'Institution') {
		return <Redirect to='/insights/create' />;
	}

	React.useEffect(() => props.searchInsights(props.searchArgs, 1, 8), []);

	return (
		<PageLayout 
			title="Research Insights" 
			pageContent={
				<React.Fragment>
					<Grid container spacing={3} alignItems="flex-end">
						<Grid item xs={12} sm={3}>{fields.search()}</Grid>
						<Grid item xs={12} sm={3}>{fields.contentType()}</Grid>
						<Grid item xs={12} sm={3}>{fields.tag()}</Grid>
						<Grid item xs={12} sm={3}>{fields.channel()}</Grid>
						{/* {props.canSubmit && 
							<Grid item xs="auto">
								<ButtonLink to="/insights/create">Create new insight</ButtonLink>
							</Grid>
						} */}
						{!props.canPublishInsights && props.insightRequestPublish && 
							<Grid item xs="auto">
								<Button 
									href="https://www.research2business.com/publish-request"
									color="secondary" 
									target="_blank"
								>
									Publish Request
								</Button>
							</Grid>
						}
					</Grid>
					{insights.length === 0 ? <p style={{ marginTop: 10 }}>No insights found...</p> :
						<Grid container spacing={3}>
							{map(insights, (i, index) => <InsightCard key={index} insight={i} isCommunity={props.isCommunity} />)}
						</Grid>
					}
					<PagePagination
						showCount={false}
						pageNum={props.pageNum}
						pageSize={props.pageSize}
						total={props.total}
						totalPages={props.totalPages}
						onChangePage={page => {
							props.updateValues({
								...props.values,
								_pageNum: page,
								_pageSize: props.pageSize
							});
							props.searchInsights(props.values, page, props.pageSize);
						}}
						onChangeRowsPerPage={rowsPerPage => {
							props.updateValues({
								...props.values,
								_pageNum: 1,
								_pageSize: rowsPerPage
							});
							props.searchInsights(props.values, 1, rowsPerPage);
						}}
					/>
				</React.Fragment>
			} 
			breadcrumbs={[props.isCommunity ? { label: "My Community", path: "/my-community" } : "", "Research Insights"]} 
			pageActions={
				props.canMakePublic ?
					<MakePublicButton />
					: undefined
			}
		/>
	);
};

InsightsController.propTypes = { 
	insights: PropTypes.arrayOf(PropTypes.object).isRequired,
	searchInsights: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	isCommunity: PropTypes.bool,
	organisationType: PropTypes.string,
	pageNum: PropTypes.number,
	pageSize: PropTypes.number,
	totalPages: PropTypes.number,
	total: PropTypes.number,
	canMakePublic: PropTypes.bool.isRequired,
	canPublishInsights: PropTypes.func.isRequired
};

InsightsController.defaultProps = {
	isCommunity: false,
	organisationType: '',
	pageNum: PropTypes.null,
	pageSize: PropTypes.null,
	totalPages: PropTypes.null,
	total: PropTypes.null
};

const mapStateToProps = (state, ownProps) => ({
	insights: state.insights.insights,
	pageNum: state.insights.pageNum,
	pageSize: state.insights.pageSize,
	total: state.insights.total,
	totalPages: state.insights.totalPages,
	loading: isLoading(state),
	canSubmit: canSubmitInsights(state),
	searchArgs: pick(getQueryParameters(ownProps.location.search), "search"),
	organisationType: state.context.organisationType,
	canMakePublic: canMakePublic(state),
	insightRequestPublish: hasFunction("InsightRequestPublish")(state),
	canPublishInsights: hasFunction("CanPublishInsights")(state)
});

const mapDispatchToProps = dispatch => ({
	searchInsights: (args, pageNum, pageSize) => dispatch(searchInsights(args, pageNum, pageSize))
});

export default connect(mapStateToProps, mapDispatchToProps)(withForm(form)(InsightsController));
