import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import MediaCard from '../widgets/MediaCard';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import FavouriteIcon from '@mui/icons-material/Favorite';
import UnfavouriteIcon from '@mui/icons-material/FavoriteBorder';
import { 
	rateMicroLearn,
	favouriteMicroLearn, 
	unfavouriteMicroLearn, 
	isRatingLoading,
	isFavouriteLoading,
	capabilityThemes
} from '../../store/microlearn';
import find from 'lodash/find';

const MicroLearnCard = React.memo(props => {
	const { microLearn } = props
	const capabilityTheme = find(capabilityThemes, t => t.value === microLearn.capabilityTheme);
	const capabilityThemeImageUrl = capabilityTheme ? capabilityTheme.imageUrl : "";

	return (
		<Grid item xs={12} sm={6} md={3} lg={3}>
			<MediaCard 
				className="microLearn-card" 
				image={microLearn.image || capabilityThemeImageUrl} 
				link={`/microlearn/${microLearn.microLearnId}`} 
				title={microLearn.title}
				bodyStyle={{
					position: 'relative'
				}}
				rootContent={
					<div
						style={{
							position: 'absolute',
							top: '5px',
							right: '5px',
						}}
					>
					{props.isRatingLoading ? 
						<CircularProgress />
						:
						<IconButton 
							size="large"
							color="red"
							style={{ color: '#d1332e', background: '#fff' }}
							onClick={() => {
								if (microLearn.isFavourite) {
									props.unfavouriteMicroLearn(microLearn.microLearnId);
								} else {
									props.favouriteMicroLearn(microLearn.microLearnId);
								}
							}}
						>
							{microLearn.isFavourite ? <FavouriteIcon /> : <UnfavouriteIcon />}
						</IconButton>
					}
					</div>
				}
			>
				<div
					style={{
						position: 'absolute',
						top: '5px',
						right: '5px'
					}}
				>
					{props.isRatingLoading ? 
						<CircularProgress />
						:
						<Rating
							value={microLearn.rating}
							onChange={(e, newValue) => {
								props.rateMicroLearn(microLearn.microLearnId, newValue);
							}}
						/>
					}
				</div>
				<div className="summary">
					{microLearn.summary.length === 0 ? "No summary provided." : microLearn.summary}
				</div>
				<br />
				<div
					style={{
					}}
				>
					{`Creator: ${microLearn.creatorName}`}
				</div>
				<div
					style={{
					}}
				>
					{`Institution: ${microLearn.creatorInstitution}`}
				</div>
			</MediaCard>
		</Grid>
	);
});

MicroLearnCard.propTypes = {
	microLearn: PropTypes.object.isRequired
};

MicroLearnCard.defaultProps = {
};

const mapStateToProps = state => ({
	isRatingLoading: isRatingLoading(state),
	isFavouriteLoading: isFavouriteLoading(state)
});

const mapDispatchToProps = dispatch => ({
	rateMicroLearn: (microLearnId, rating) => dispatch(rateMicroLearn(microLearnId, rating)),
	favouriteMicroLearn: (microLearnId) => dispatch(favouriteMicroLearn(microLearnId)),
	unfavouriteMicroLearn: (microLearnId) => dispatch(unfavouriteMicroLearn(microLearnId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MicroLearnCard);