import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchSettings } from '../store/settings';
import PageBanner from './PageBanner';
import classNames from 'classnames';
import LogoSrc from './images/logo/research2u_logo.png';
import { Helmet } from "react-helmet";

const AnonymousLayout = props => {
	React.useEffect(() => {
		props.fetchSettings([
			"showPageBanner",
			"pageBannerContent",
			"loginBackground"
		]);
	}, []);

	const backgroundImage = props.loading ? null : props.backgroundImage || props.loginBackground || "/images/login_background.jpg";

	return (
		<main role="main" className={classNames({ "show-banner": props.showBanner, "anonymous_-layout": true })}>
			<Helmet>
                {props.favicon && <link rel="shortcut icon" href={props.favicon} />}
            </Helmet>

			{props.showBanner && <PageBanner />}
			<div className="anonymous__main">
				<div className="anonymous__background" style={{ backgroundImage: `url(${backgroundImage})` }} />
				<div className="anonymous__content">
					<h1 className="anonymous__logo logo-primary text-center">
						{!props.loading && <Link to="/"><img src={props.logo || LogoSrc} height="100" className="logo" alt="Research 2 U" /></Link>}
					</h1>
					{props.children}
				</div>
			</div>
			{/* <Footer /> */}
		</main>
	);
};

AnonymousLayout.props = {
	loading: PropTypes.bool.isRequired,
	showBanner: PropTypes.bool.isRequired,
	logo: PropTypes.string,
	loginBackground: PropTypes.string,
	backgroundImage: PropTypes.string,
	favicon: PropTypes.string
};

AnonymousLayout.defaultProps = {
	logo: null,
	loginBackground: null,
	backgroundImage: null,
	favicon: null
};

const mapStateToProps = state => ({
	showBanner: state.settings.showPageBanner,
	logo: state.settings.logo,
	loading: state.bootstrap.loading,
	loginBackground: state.settings.loginBackground,
	backgroundImage: state.settings.backgroundImage,
	favicon: state.settings.favicon
});

const mapDispatchToProps = dispatch => ({
	fetchSettings: names => dispatch(fetchSettings(names))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnonymousLayout));