import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import map from 'lodash/map';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { FormHelperText } from '@mui/material';

const ImageSelector = React.memo(props => {
	const [id] = React.useState(props.id || uniqueId("image-selector-"));
	const [newImage, setNewImage] = React.useState(null);

	React.useEffect(() => {
		// Sets new image to value if not provided
		if (!newImage && props.value && ![...props.images, "new-image"].includes(props.value)) {
			setNewImage(props.value);
		}
	}, [props.value]);

	const uploadImage = e => {
		if (e.target.files && e.target.files[0]){
			let reader = new FileReader();

			reader.onload = ev => setNewImage(ev.target.result);
			reader.onload.bind(this);
			reader.readAsDataURL(e.target.files[0]);
		}

		props.onChange("new-image", e.target.files[0]);
	}

	const images = [...props.images],
		{ style, value, error, helpText, background } = props;

	const handleRemove = () => {
		setNewImage(null);
		props.onChange(images.length === 1 ? images[0] : "");
	}

	return (
        <div id={id} style={style} className="image-selector">
			{props.label && 
				<label style={{ color: error ? props.errorColour : "" }}>
					{props.label}
					{props.required && <span style={{ color: props.errorColour }}> *</span>}
				</label>
			}
			<div className="image-list">
				{map(images, (i, index) =>
					<img 
						key={index}
						className={classNames("image-item", { "selected": i === value })}
						src={i} 
						alt=""
						onClick={() => props.onChange(i)}
						style={{ background }}
					/>	
				)}
				{!newImage && 
					<React.Fragment>
						<input
							id={`${id}-upload`}
							accept={props.accept}
							type="file"
							onChange={uploadImage}
							style={{ display: "none" }}
						/>
						<label htmlFor={`${id}-upload`} className="upload-image">
							<span className="upload-text"><AddIcon className="upload-icon" />Upload</span><br />
							<span className="upload-help-text">
								{props.imageText}
							</span>
						</label>
					</React.Fragment>
				}
				{newImage &&
					<span style={{ position: "relative" }}>
						<img 
							className={classNames("image-item", { "selected": !images.includes(value) })}
							src={newImage}
							alt="" 
							onClick={() => props.onChange("new-image")}
							style={{ background }}
						/>
						<IconButton
                            aria-label="Remove"
                            onClick={handleRemove}
                            style={{ position: "absolute", top: 10, right: 10 }}
                            size="large">
							<ClearIcon style={{ color: "white", mixBlendMode: "exclusion" }} />
						</IconButton>
					</span> 
				}	
			</div>
			{helpText && <FormHelperText style={{ color: error ? props.errorColour : "" }}>{helpText}</FormHelperText>}
		</div>
    );
});

ImageSelector.propTypes = {
	id: PropTypes.string,
	images: PropTypes.array,
	style: PropTypes.object,
	value: PropTypes.string,
	label: PropTypes.string,
	helpText: PropTypes.node,
	required: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	height: PropTypes.number,
	width: PropTypes.number,
	background: PropTypes.string,
	imageText: PropTypes.string,
	errorColour: PropTypes.string.isRequired,
	accept: PropTypes.string
};

ImageSelector.defaultProps = {
	id: "",
	value: "",
	label: "",
	helpText: "",
	required: false,
	images: [],
	style: {},
	height: 780,
	width: 500,
	background: "#fff",
	imageText: "For optimal display purposes, please resize your image to 500x780 pixels before uploading",
	accept: "image/*"
};

const mapStateToProps = state => ({
	errorColour: state.theme.errorColour
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelector);
