import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { 
	Avatar, 
	CircularProgress,
} from '@mui/material';
import { canViewProps, hasAnyFunction, hasAnyRole, hasFunction, hasFunctions, hasRole, hasRoles } from '../store/security';
import { resetAccess } from '../store/context';
import { getSetting } from '../store/settings';
import { getStats } from '../store/dashboard';
import Startup from '../Startup';
import TopBar from './TopBar';
// import NavBar from './NavBar';
import PageBanner from './PageBanner';
import Footer from './Footer';
import SideMenu from './SideMenu';
import SidePanel from './SidePanel';
import NotAuthorised from './NotAuthorised';
import classNames from 'classnames';
import UserOrganisationDialog from './widgets/UserOrganisationDialog';
import BusinessProfileDialog from './businessProfiles/BusinessProfileDialog';
import { hubConnection } from '../messageHub';
import ChatButton from './widgets/ChatButton';
import { showInfoNotification } from '../store/notifications';
import {
    toggleChat,
    setToUser
} from '../store/messages';
import { toggleUserTour, toggleUserOrganisationDialog, toggleBusinessProfileDialog } from '../store/layout';
import { requireBusinessProfile } from '../store/security';
import { Helmet } from "react-helmet";

const loadingStyles = {
	height: "100%",
	marginTop: "-10px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
};

const Layout = props => {
	const { history, section = history.location.pathname.match(/\/([^/]*)/)[1], pageLoaded } = props;

	if (pageLoaded && !props.isLoggedIn) { 
		return <Redirect to={`/login?redirectUrl=${encodeURIComponent(history.location.pathname)}${encodeURIComponent(history.location.search)}`} />;
	}

	React.useEffect(() => {
		if (pageLoaded) {
			props.toggleUserOrganisationDialog(props.registerUserOrganisation && !props.userOrganisation);
			props.toggleUserTour(props.showUserTour);
			if (props.requireBusinessProfile) {
				props.toggleBusinessProfileDialog(!props.hasBusinessProfile);
			}
		}
	}, [pageLoaded]);

	React.useEffect(() => {
		hubConnection.on("new-message", data => {
            props.showInfoNotification(
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex'
                    }}
                    onClick={() => { 
                        props.toggleChat(true);
                        props.setChatToUser(data.message.sentByUserId);
                    }}
                >
                    <div>
					    <Avatar src={data.message.sentByAvatar} length={45} />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <span className="h5" style={{ color: 'inherit' }}>{data.message.sentBy}</span>
                        <div className="box">{data.message.content}</div>
                    </div>
                </div>
            );
		});
	}, []);

	React.useEffect(() => {
		if (props.isLoggedIn && props.canView[section]) {
			props.getSetting("logo");
			props.getStats();
		}
		
		// window.scrollTo({ top: 0, behavior: "smooth" }); // Use for smooth scrolling effect
		window.scrollTo(0, 0);
		
		if (!props.hasAccess) props.resetAccess();
	}, [history.location]);
	
	if (pageLoaded) {
		// Check if user has view access
		if (!props.canView[section]) {
			console.log(`No view access to ${section}`);
			return <NotAuthorised />;
		}
	
		// Check if access was revoked
		if (!props.hasAccess) {
			console.log("Access was revoked for this page");
			return <NotAuthorised />;
		}

		// Check for required function
		if (props.function && !props.hasFunction(props.function)) {
			console.log(`Missing required function: ${props.function}`);
			return <NotAuthorised />;
		}

		// Check for required functions
		if (props.functions.length > 0 && !props.hasFunctions(props.functions)) {
			console.log(`Missing required functions: ${props.functions.join(", ")}`);
			return <NotAuthorised />;
		}

		// Check for any required function
		if (props.anyFunction.length > 0 && !props.hasAnyFunction(props.anyFunction)) {
			console.log(`Missing one of required functions: ${props.anyFunction.join(", ")}`);
			return <NotAuthorised />;
		}		

		// Check for required role
		if (props.role && !props.hasRole(props.role)) {
			console.log(`Missing required role: ${props.role}`);
			return <NotAuthorised />;
		}

		// Check for required roles
		if (props.roles.length > 0 && !props.hasRoles(props.roles)) {
			console.log(`Missing required roles: ${props.roles.join(", ")}`);
			return <NotAuthorised />;
		}

		// Check for any required role
		if (props.anyRole.length > 0 && !props.hasAnyRole(props.anyRole)) {
			console.log(`Missing one of required roles: ${props.anyRole.join(", ")}`);
			return <NotAuthorised />;
		}
	}

	return (
		<main 
			role="main" 
			className={classNames(
				"main-layout", 
				{ 
					"show-banner": props.showBanner,
					"is-public-link": props.isPublicLink
				}
			)} 
			style={{ overflow: props.isSideMenuOpen && "hidden" }}
		>
            <Helmet>
                <title>{props.tenantTitle}</title>
                {props.favicon && <link rel="shortcut icon" href={props.favicon} />}
            </Helmet>

			<TopBar logo={props.logo} />
			{!pageLoaded ? <div style={loadingStyles}><CircularProgress /></div> :
				<Startup>
					{/* {!props.isPublicLink && <NavBar active={section} />} */}

					{props.showBanner && <PageBanner />}
					{!props.isPublicLink && <SidePanel active={section} />}
					<div className="content-wrapper">
						{!props.isPublicLink && <SideMenu active={section} history={history} />}
						
						{props.children}
					</div>
					<Footer 
						logo={props.logo} 
						tenantHasCopyright={props.tenantHasCopyright} 
						tenantTitle={props.tenantTitle} 
					/>
					<UserOrganisationDialog />
					<BusinessProfileDialog />
					{!props.isPublicLink && <ChatButton />}
				</Startup>
			}
		</main>
	);
};

Layout.propTypes = {
	section: PropTypes.string,
	function: PropTypes.string,
	functions: PropTypes.array,
	anyFunction: PropTypes.array,
	role: PropTypes.string,
	roles: PropTypes.array,
	anyRole: PropTypes.array,
	hasAccess: PropTypes.bool,
	showInfoNotification: PropTypes.func.isRequired,
    setChatToUser: PropTypes.func.isRequired,
    toggleChat: PropTypes.func.isRequired,
    toggleUserTour: PropTypes.func.isRequired,
    toggleUserOrganisationDialog: PropTypes.func.isRequired,
    toggleBusinessProfileDialog: PropTypes.func.isRequired,
	requireBusinessProfile: PropTypes.bool,
	tenantHasCopyright: PropTypes.bool,
	tenantTitle: PropTypes.string,
	isPublicLink: PropTypes.bool,
	favicon: PropTypes.string
};

Layout.defaultProps = {
    userId: PropTypes.string.isRequired,
	section: undefined, // Needs to be undefined to overwrite default in destructuring
	function: "",
	functions: [],
	anyFunction: [],
	role: "",
	roles: [],
	anyRole: [],
	hasAccess: true,
	requireBusinessProfile: false,
	tenantHasCopyright: false,
	tenantTitle: '',
	isPublicLink: false,
	favicon: null
};

const mapStateToProps = state => ({
	isLoggedIn: state.context.isLoggedIn,
	showBanner: state.settings.showPageBanner,
	isSideMenuOpen: state.layout.isSideMenuOpen,
	canView: canViewProps(state),
	logo: state.settings.logo,
	userId: state.context.userId,
	pageLoaded: !state.bootstrap.loading,
	hasFunction: name => hasFunction(name)(state),
	hasFunctions: names => hasFunctions(names)(state),
	hasAnyFunction: names => hasAnyFunction(names)(state),
	hasRole: name => hasRole(name)(state),
	hasRoles: names => hasRoles(names)(state),
	hasAnyRole: names => hasAnyRole(names)(state),
	hasAccess: state.context.hasAccess,
	registerUserOrganisation: state.context.registerUserOrganisation,
	userOrganisation: state.context.userOrganisation,
	showUserTour: state.context.showUserTour,
	hasBusinessProfile: state.context.hasBusinessProfile,
	requireBusinessProfile: requireBusinessProfile(state),
	tenantHasCopyright: state.context.tenantHasCopyright,
	tenantTitle: state.context.tenantTitle,
	isPublicLink: state.context.isPublicLink,
	favicon: state.settings.favicon
});

const mapDispatchToProps = dispatch => ({
	getSetting: name => dispatch(getSetting(name)),
	getStats: () => dispatch(getStats()),
	resetAccess: () => dispatch(resetAccess()),
	showInfoNotification: message => dispatch(showInfoNotification(message)),
    setChatToUser: userId => dispatch(setToUser(userId)), 
    toggleChat: show => dispatch(toggleChat(show)),
	toggleUserTour: show => dispatch(toggleUserTour(show)),
	toggleUserOrganisationDialog: show => dispatch(toggleUserOrganisationDialog(show)),
	toggleBusinessProfileDialog: show => dispatch(toggleBusinessProfileDialog(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
