import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageLayout from '../PageLayout';
import EditStudentProject from './EditStudentProject';
import { saveStudentProject, isLoading } from '../../store/studentProjects';

const CreateStudentProjectController = props => 
	<PageLayout title="Create Challenge" pageContent={
		<EditStudentProject saveProject={props.saveProject} loading={props.loading} isNew />
	} breadcrumbs={[{ label: "Business Challenges", path: "/business-challenges" }, "Create Business Challenge"]} />;

CreateStudentProjectController.propTypes = {
	saveProject: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	loading: isLoading(state)
});

const mapDispatchToProps = dispatch => ({
	saveProject: (project, files) => dispatch(saveStudentProject(project, files))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStudentProjectController);