import React from 'react';
import PropTypes from 'prop-types';
import { withForm } from '../../utils/forms';
import {
    Accordion,
	AccordionDetails,
	AccordionSummary,
    CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
    List, 
	ListItem, 
	ListItemText, 
	ListItemIcon, 
	Radio,
	RadioGroup,
	Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '../widgets/Card';
import TextField from '../widgets/TextField';
import Wizard from '../widgets/Wizard/Wizard';
import FileUploader from '../widgets/FileUploader';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import { get, post } from '../../utils/ajax';
import axios from 'axios';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import take from 'lodash/take';
import { channelImages } from './InsightImages';
import Link from 'react-router-dom/Link';

const form = {
	initValues: ({ 
		userId,
		insight
	}) => ({ 
        authorId: userId, 
        layoutType: "Flat", 
        flatContent: insight ? insight.content : "", 
        ...insight 
    }),
	fields: [
		{
			name: "title",
			label: "Title",
			required: true,
			helpText: "Title of insight (max 60 characters)",
		},
		{
			name: "description",
			label: "Summary",
			multiline: true
			// helpText: "(max 250 characters)",
			// max: 250,
		},
		{
			name: "authorName",
			label: "Author",
		},
		{
			name: "authors",
			label: "Authors",
		},
		{
			name: "content",
			label: "Research Insight",
			type: "textEditor",
			helpText: "(no more than 1,200 characters)",
			max: (props, values) => values.layoutType === "Flat" ? undefined : 1200
		},
		{
			name: "keyFindings",
			label: "Key Findings",
			type: "textEditor",
			helpText: "(no more than 2,400 characters)",
			max: 2400,
		},
		{
			name: "implicationsForPractice",
			label: "Implications For Practice",
			type: "textEditor",
			helpText: "(no more than 2,400 characters)",
			max: 2400,
		},
		{
			name: "links",
			label: "Website Links",
			type: "dragList",
			itemTemplate: { label: "", to: "" },
			renderItem: (item, index, updateItem) =>
				<Grid key={index} container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField label="Label" value={item.label} onChange={e => updateItem(e.target.value, "label")} />
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField startAdornment="https://" label="Link" value={item.to} onChange={e => updateItem(e.target.value, "to")} />
					</Grid>
				</Grid>
		},
		{
			name: "videos",
			label: "Videos",
			type: "dragList",
			itemTemplate: "",
			renderItem: (value, index, updateItem) =>
				<TextField key={index} label="Link" value={value} onChange={e => updateItem(e.target.value)} />
		},
		{
			name: "image",
			label: "Image",
			type: "imageUploader",
			single: true
		},
		{
			name: "expertId",
			label: "Expert",
			type: "autoComplete",
			loadItems: {
				route: "experts",
				mapItem: e => ({ ...e, label: e.fullName, value: e.expertId })
			}
		},
		{
			name: "channels",
			label: "Channels",
			type: "autoComplete",
			isMulti: true,
			isSingleMulti: true,
			loadItems: {
				route: "channels",
				mapItem: c => ({ ...c, label: c.name, value: c.channelId })
			},
			mapValue: c => ({ ...c, label: c.name, value: c.channelId }),
			chipColour: (props) => props.primaryColour,
			validate: channels => channels.length > 1 ? "Please select only one channel" : ""
		},
		{
			name: "tags",
			label: "Tags",
			type: "tagEditor"
		},
		{
			name: "attachments",
			label: "Attachments",
			type: "insightAttachmentEditor",
			download: attachment => {
				window.open(`/Insight/DownloadAttachment?insightId=${attachment.insightId}&attachmentId=${attachment.insightAttachmentId}`, "_self");
			}
		},
		{
			name: "isBlog",
			label: "Is Blog",
			type: "checkbox"
		},
		{
			name: "isJournalArticle",
			label: "Is Journal Article",
			type: "checkbox"
		},
		{
			name: "isVideo",
			label: "Is Video",
			type: "checkbox"
		},
		{
			name: "articleType",
			label: "Article Type",
			required: true,
			type: "select",
			items: ['Blog', 'Publication', 'Video']
		},
		{
			name: "contentType",
			label: "Publication Type",
			required: true,
			type: "select",
			items: [
				{ label: "Academic Authored", value: "Academic" },
				{ label: "Industry Authored", value: "Industry" },
				{ label: "Market Insight", value: "MarketInsight" }
			]
		},
		{
			name: "layoutType",
			type: "custom",
			widget: props => 
				<FormControl fullWidth={true} error={props.error} style={{
					textAlign: 'left',
					...props.style
				}}>
					<FormLabel>Layout:</FormLabel>
					<RadioGroup id="insight-layout" row value={props.value} onChange={e => props.onChange(e.target.value)} >
						<FormControlLabel value="Structured" control={<Radio />} label="Structured" />
						<FormControlLabel value="Flat" control={<Radio />} label="Flat" />
					</RadioGroup>
				</FormControl>
		},
		{
			name: "flatContent",
			label: "Research Insight",
			type: "textEditor",
			getValue: values => {
				return values.content;
			},
			setValues: (newValue, values) => ({ ...values, content: newValue, flatContent: newValue  }),
			required: (props, i) => i.layoutType === "Flat"
		},
		{
			name: "pdf",
			label: "PDF",
			type: "custom",
            onChange: (values, value) => {
                values.layoutType = "Flat";
                if (values.title === '') values.title = value.title || '';
                values.description = value.description || '';
                values.authors = value.authors || '';
                values.content = values.flatContent = value.flatContent || value.content || '';
                values.keyFindings = value.keyFindings || '';
                values.implicationsForPractice = value.implicationsForPractice || '';
			},
			widget: props => {
				const [fileValue] = React.useState(null);
				const [loading, setLoading] = React.useState(false);
				const [analyzeResult, setAnalyzeResult] = React.useState(null);

                const analyzePdf = (file) => {
                    const formData = new FormData();
                    formData.append("File", file);
                    setLoading(true);
                    setAnalyzeResult(null);
                    post({
                        url: `/api/insights/analyze-pdf`,
                        data: formData,
                        onSuccess: (data) => {
                            setLoading(false);
                            if (data.success) {
                                // const headers = [
                                //     "Title",
                                //     "Authors",
                                //     "Summary",
                                //     "Research Insights",
                                //     "Key Findings",
                                //     "Implications for Practitioners",
                                //     "Conclusions",
                                //     "Citation"
                                // ];

                                // const headerMatches = {};

                                // // Construct regular expressions for each header
                                // for (const header of headers) {
                                //     const regex = new RegExp(`${header}:\\s*(.*?)\\n`, "g");
                                //     const matches = [];
                                //     let match;

                                //     while ((match = regex.exec(data.data)) !== null) {
                                //         matches.push(match[1]);
                                //     }

                                //     if (matches.length > 0) {
                                //         headerMatches[header] = matches;
                                //     }
                                // }

                                const addWithHeader = (text, header, addText) => {
                                    return `${text}<br /><h1>${header}</h1><p>${addText}</p>`;
                                };

                                const result = {};
                                let content = "";
                                result.title = data.title || '';
                                result.authors = data.authors ? data.authors.join(", ") : '';
                                result.description = data.summary || '';
                                result.reference = data.citations ? data.citations.join("\n") : '';
                                if (data.researchInsights) {
                                    content = `<p>${data.researchInsights}</p>`;
                                    result.content = data.researchInsights;
                                }
                                if (data.keyFindings) {
                                    result.keyFindings = data.keyFindings ? data.keyFindings.join("\n") : '';
                                    content = addWithHeader(content, "Key Findings", result.keyFindings);
                                }
                                if (data.implicationsForPractice) {
                                    result.implicationsForPractice = data.implicationsForPractice ? data.implicationsForPractice.join("\n") : '';
                                    content = addWithHeader(content, "Implications for Practitioners", result.implicationsForPractice);
                                }
                                if (data.conclusion) {
                                    content = addWithHeader(content, "Conclusions", data.conclusion);
                                }

                                // if (headerMatches.Title && headerMatches.Title.length > 0) result.title = headerMatches.Title[0];
                                // if (headerMatches.Authors && headerMatches.Authors.length > 0) result.authors = headerMatches.Authors[0];
                                // if (headerMatches.Summary && headerMatches.Summary.length > 0) {
                                //     result.description = headerMatches.Summary[0];
                                // }
                                // if (headerMatches["Research Insights"] && headerMatches["Research Insights"].length > 0) {
                                //     content = `<p>${headerMatches["Research Insights"][0]}</p>`;
                                //     result.content = headerMatches["Research Insights"][0];
                                // }
                                // if (headerMatches["Key Findings"] && headerMatches["Key Findings"].length > 0) {
                                //     result.keyFindings = headerMatches["Key Findings"][0];
                                //     content = addWithHeader(content, "Key Findings", headerMatches["Key Findings"][0]);
                                // }
                                // if (headerMatches["Implications for Practitioners"] && headerMatches["Implications for Practitioners"].length > 0) {
                                //     content = addWithHeader(content, "Implications for Practitioners", headerMatches["Implications for Practitioners"][0]);
                                //     result.implicationsForPractice = headerMatches["Implications for Practitioners"][0];
                                // }
                                // if (headerMatches["Conclusions"] && headerMatches["Conclusions"].length > 0) content = addWithHeader(content, "Conclusions", headerMatches["Conclusions"][0]);
                                // if (headerMatches["Citation"] && headerMatches["Citation"].length > 0) result.reference = headerMatches["Citation"][0];

                                // if (headerMatches.Title && headerMatches.Title.length > 0) result.title = headerMatches.Title[0];
                                // if (headerMatches.Authors && headerMatches.Authors.length > 0) result.authors = headerMatches.Authors[0];
                                // if (headerMatches.Summary && headerMatches.Summary.length > 0) result.description = headerMatches.Summary[0];
                                // if (headerMatches["Key Findings"] && headerMatches["Key Findings"].length > 0) result.keyFindings = headerMatches["Key Findings"][0];
                                // if (headerMatches["Implications for Practitioners"] && headerMatches["Implications for Practitioners"].length > 0) result.implicationsForPractice = headerMatches["Implications for Practitioners"][0];
                                
                                result.flatContent = content;
                                result.file = file;
                                // file.reference = result.reference || '';
                                // props.onChange(result);

                                props.onChangeForm((form) => {
                                    form.values = { 
                                        ...form.values ,
                                        pdf: result,
                                        attachments: [{
                                            fileName: file.name,
                                            name: file.name,
                                            sizeBytes: file.size,
                                            attachmentType: "document",
                                            reference: result.reference || '',
                                            _isNew: true
                                        }, ...form.values.attachments],
                                        layoutType: "Flat",
                                        title: form.values.title || result.title || '',
                                        description: result.description || '',
                                        authors: result.authors || '',
                                        content: result.flatContent || result.content || '',
                                        flatContent: result.flatContent || '',
                                        keyFindings: result.keyFindings || '',
                                        implicationsForPractice: result.implicationsForPractice || ''
                                    };
                                    form.formFiles['attachments'] = [
                                        file,
                                        ...form.formFiles['attachments']
                                    ];
                                })
                            }
                            setAnalyzeResult(data);
                        },
                        onError: (error) => {
                            setLoading(false);
                            setAnalyzeResult({
                                success: false,
                                message: error.message
                            });

                        }
                    });
                }

                if (loading) {
                    return (
                        <React.Fragment>
                            <div>
                            <p>
                                Analyzing PDF... 
                            </p>
                            <CircularProgress />
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <p>Select a file to analyze</p>

                            <FileUploader
                                required={true}
                                value={fileValue}
                                label="PDF"
                                accept=".pdf"
                                onChange={e => {
                                    if (e.target.files) {
                                        const file = e.target.files[0];
                                        analyzePdf(file);
                                    }
                                }}
                            />

                            {analyzeResult && 
                                <List dense>
                                    <ListItem style={{ paddingTop: 0 }}>
                                        <ListItemIcon>
                                            {analyzeResult.success && <SuccessIcon style={{ color: "#35aa47" }} />}
                                            {!analyzeResult.success && <ErrorIcon style={{ color: "#d1332e" }} />}
                                        </ListItemIcon>
                                        {analyzeResult.success && <ListItemText primary="The file has been analyzed. Click Next to view results and create the Insight."></ListItemText>}
                                        {!analyzeResult.success && <ListItemText primary={analyzeResult.message}></ListItemText>}
                                    </ListItem>
                            </List>
                            }
                        </React.Fragment>
                    );
                }
            }
		},
	]
};

let cancelToken = null;

const getSimilarInsights = debounce((title, callback) => {
	callback(title);
}, 500);


const CreateInsight = props => {
	const { fields, values } = props;
	const [similarInsights, setSimilarInsights] = React.useState({data: []});
	const [showSimilarInsights, setShowSimilarInsights] = React.useState(false);
	const [loadingSimilarInsights, setLoadingSimilarInsights] = React.useState(false);

	const fetchSimilarInsights = (title) => {
		setLoadingSimilarInsights(true);

		if (cancelToken) cancelToken.cancel();
		cancelToken = axios.CancelToken.source();

		get({
			url: `/api/insights/get-by-title?title=${title}`,
			cancelToken: cancelToken.token,
			onSuccess: data => {
				if (data.total > 0) {
					setShowSimilarInsights(true);
				}
				setLoadingSimilarInsights(false);
				setSimilarInsights(data);
			},
			onError: (a,b,c) => {
				setLoadingSimilarInsights(false);
				setSimilarInsights({data: []});
			}
		});

	};

	const errors = [];
	forEach(Object.keys(props.error), (fieldName, i) => {
		if (props.error[fieldName]) {
			errors.push(`${find(form.fields, f => f.name === fieldName).label}: ${props.error[fieldName]}`);
		}
	});

    React.useEffect(() => {
		if (values) {
			if (values.title) {
				getSimilarInsights(values.title, (title) => {
					fetchSimilarInsights(title);
				});
			} else {
				setSimilarInsights({data: []});
			}
		}
	}, [values.title])

	return (
		<Card className="create-insight">
			<Wizard
                showProgress={false}
                errors={errors}
                okButtonText="Create Insight"
                onCancel={props.onClose}
                // getPrevStep={(currentStep) => {
                //     if (currentStep === 3) {
                //         const canAnalyzePdf = values.contentType === 'Academic' && values.articleType === 'Publication';
                //         return canAnalyzePdf ? 2 : 1;
                //     } else {
                //         return currentStep - 1;
                //     }
                // }}
                // getNextStep={(currentStep) => {
                //     if (currentStep === 1) {
                //         const canAnalyzePdf = values.contentType === 'Academic' && values.articleType === 'Publication';
                //         return canAnalyzePdf ? { nextStep: 2, isLastStep: false } : { nextStep: 3, isLastStep: false };
                //     } else {
                //         return { nextStep: 2, isLastStep: currentStep === 2 };
                //     }
                // }}
                onComplete={(onSuccess, onError) => {
                    props.saveInsight(
                        values, 
                        props.formFiles.image, 
                        props.formFiles.attachments,
                        props.formFiles.userTour
                    );
                }}
                steps={[
                    {
                        number: 1,
                        title: 'Insight Type',
                        validate: () => {
                            const fields = ["articleType", "contentType", "channels", "tags", "pdf"];
                            return props.validateFields(fields);
                        },
                        content:
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>{fields.articleType()}</Grid>
                                <Grid item xs={12} sm={6}>{fields.contentType()}</Grid>
                                {props.canAnalyzePDFs && values.contentType === 'Academic' && values.articleType === 'Publication' &&
                                    <Grid item xs={12}>{fields()}</Grid>
                                }
                                {showSimilarInsights &&
                                    <Grid item xs={12}>
                                        <Accordion
                                            defaultExpanded={true}
                                            style={{
                                                width: 'calc(100% + 40px)',
                                                marginLeft: '-20px',
                                                borderRadius: 0,
                                                background: '#eee',
                                                boxShadow: 'none',
                                                borderTop: '1px solid #ddd',
                                                borderBottom: '1px solid #ddd'
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                            <Typography>Similar Insights</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{
                                                    flexDirection: 'column',
                                                    padding: 0
                                                }}
                                            >
                                                {loadingSimilarInsights && <CircularProgress size={24} style={{ marginLeft: 15 }} />}
                                                {!loadingSimilarInsights && map(take(similarInsights.data, 2), (item) => {
                                                    const i = item;
                                                    const numChannels = i.channels ? i.channels.length : 0;
                                                    const image = numChannels > 0 && channelImages[i.channels[0].name] ? channelImages[i.channels[0].name] : null;
                                                    const link = `/insights/${i.insightId}`;
                                                    const imageComponent = <img className="pcard-img-top img-responsive" src={image} />;
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                borderTop: '1px solid #ddd',
                                                                padding: '10px'
                                                            }}
                                                        >
                                                            {image && <Link to={link} style={{ flex: 1 }}>{imageComponent}</Link>}
                                                            <div style={{ flex: 6, paddingLeft: '10px' }}>
                                                                <Link to={link}><h3>{i.title}</h3></Link>
                                                                {i.authors &&
                                                                    <div className="insight-authors" style={{ fontStyle: 'italic', fontSize: 'small' }}>
                                                                        {i.authors}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                <Grid item xs={12}>{fields.channels()}</Grid>
                                <Grid item xs={12}>{fields.tags()}</Grid>
                            </Grid>
                    },
                    {
                        number: 2,
                        title: 'Insight',
                        validate: () => props.validateFields([
                            "title", 
                            "description", 
                            "expertId", 
                            "authors", 
                            "image",
                            "content",
                            "keyFindings",
                            "implicationsForPractice",
                            "flatContent",
                            "links",
                            "videos",
                            "attachments"
                        ]),
                        content: 
                            <Grid container spacing={3}>
                                <Grid item xs={12}>{fields.title()}</Grid>
                                <Grid item xs={12}>{fields.authors()}</Grid>
                                <Grid item xs={12}>{fields.description()}</Grid>
                                {props.canViewExperts &&
                                    <Grid item xs={12} sm={6}>{fields.expertId()}</Grid>
                                }
                                <Grid item xs={12}>{fields.image()}</Grid>
                                {values.layoutType === "Structured" && 
                                    <React.Fragment>
                                        <Grid item xs={12}>{fields.content()}</Grid>
                                        <Grid item xs={12}>{fields.keyFindings()}</Grid>
                                        <Grid item xs={12}>{fields.implicationsForPractice()}</Grid>
                                    </React.Fragment>
                                }
                                {values.layoutType === "Flat" && 
                                    <React.Fragment>
                                        <Grid item xs={12}>{fields.flatContent()}</Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={12}>{fields.links()}</Grid>
                                <Grid item xs={12}>{fields.videos()}</Grid>
                                <Grid item xs={12}>{fields.attachments()}</Grid>
                            </Grid>
                    }
                ]}
            />
		</Card>
	);
};

CreateInsight.propTypes = { 
	insight: PropTypes.object,
	saveInsight: PropTypes.func.isRequired,
	deleteInsight: PropTypes.func,
	loading: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	canViewExperts: PropTypes.bool.isRequired,
    canAnalyzePDFs: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

CreateInsight.defaultProps = {
	insight: {},
	deleteInsight: null,
	isNew: false
};

export default withForm(form)(CreateInsight);