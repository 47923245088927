/* eslint react/jsx-pascal-case: "off" */

import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import AnonymousLayout from './components/AnonymousLayout';
import HomeWrapper from './components/HomeWrapper';
import NotAuthorised from './components/NotAuthorised';
import AddTenantController from './components/tenants/AddTenantController';
import EditTenantController from './components/tenants/EditTenantController';
import TenantsController from './components/tenants/TenantsController';
import AddUserController from './components/users/AddUserController';
import UsersController from './components/users/UsersController';
import EditUserController from './components/users/EditUserController';
import ActivityLogsController from './components/admin/ActivityLogsController';
import UserStatsController from './components/users/UserStatsController';
import ProblemsController from './components/problems/ProblemsController';
import UserProblemsController from './components/problems/UserProblemsController';
import ViewProblemController from './components/viewProblem/ViewProblemController';
import ProblemStatsController from './components/viewProblem/ProblemStatsController';
import EditProblemController from './components/problems/EditProblemController';
import CreateProblemController from './components/problems/CreateProblemController';
import EventsController from './components/events/EventsController';
import ViewEventController from './components/events/ViewEventController';
import CreateEventController from './components/events/CreateEventController';
import EditEventController from './components/events/EditEventController';
import RequestEventController from './components/events/RequestEventController';
import OffersController from './components/offers/OffersController';
import CreateOfferController from './components/offers/CreateOfferController';
import ViewOfferController from './components/offers/ViewOfferController';
import EditOfferController from './components/offers/EditOfferController';
import NewApplicationController from './components/applications/NewApplicationController';
import ApplicationsController from './components/applications/ApplicationsController';
import ViewApplicationController from './components/applications/ViewApplicationController';
import BusinessChallengesController from './components/offers/BusinessChallengesController';
import CreateBusinessChallengeController from './components/offers/CreateBusinessChallengeController';
import ViewBusinessChallengeController from './components/offers/ViewBusinessChallengeController';
import EditBusinessChallengeController from './components/offers/EditBusinessChallengeController';
import NewBusinessChallengeApplicationController from './components/businessChallengeApplications/NewApplicationController';
import BusinessChallengeApplicationsController from './components/businessChallengeApplications/ApplicationsController';
import ViewBusinessChallengeApplicationController from './components/businessChallengeApplications/ViewApplicationController';
import UserApplicationsController from './components/applications/UserApplicationsController';
import SubmissionsController from './components/profile/SubmissionsController';
import LoginLayout from './components/login/LoginLayout';
import Login from './components/login/Login';
import RegistrationRouter from './components/login/RegistrationRouter';
import RegistrationPath from './components/login/RegistrationPath';
import ConfirmEmail from './components/login/ConfirmEmail';
import PasswordReset from './components/login/PasswordReset';
import ConfirmPasswordReset from './components/login/ConfirmPasswordReset';
import ProfileController from './components/profile/ProfileController';
import About from './components/about/About';
import Funding from './components/about/Funding';
import Copyright from './components/about/Copyright';
import Disclaimer from './components/about/Disclaimer';
import Help from './components/help/Help';
import ContactUs from './components/help/ContactUs';
import Settings from './components/admin/Settings';
import NotFound from './NotFound';
import FeaturesController from './components/features/FeaturesController';
import CreateFeatureController from './components/features/CreateFeatureController';
import EditFeatureController from './components/features/EditFeatureController';
import SearchController from './components/search/SearchController';
import IPController from './components/ipRegister/IPController';
import RegisterIPController from './components/ipRegister/RegisterIPController';
import EditIPController from './components/ipRegister/EditIPController';
import ViewIPController from './components/ipRegister/ViewIPController';
import IPEnquiriesController from './components/ipRegister/IPEnquiriesController';
import get from 'lodash/get';

// Aspire IQ
import CoursesController from './components/courses/CoursesController';
import CreateCourseController from './components/courses/CreateCourseController';
import EditCourseController from './components/courses/EditCourseController';
import SuggestCoursesController from './components/suggestCourses/SuggestCoursesController';
import ViewCourseController from './components/courses/ViewCourseController';
import CustomCourse from './components/courses/CustomCourse';
import DashboardController from './components/admin/dashboard/DashboardController';
import PassportController from './components/passport/PassportController';
import DepartmentsController from './components/departments/DepartmentsController';
import PositionsController from './components/positions/PositionsController';
import AcademicsController from './components/academics/AcademicsController';
import ViewAcademicController from './components/academics/ViewAcademicController';
import EditAcademicController from './components/academics/EditAcademicController';
import CreateAcademicController from './components/academics/CreateAcademicController';
import CommunitiesController from './components/communities/CommunitiesController';
import CreateCommunityController from './components/communities/CreateCommunityController';
import EditCommunityController from './components/communities/EditCommunityController';
import CreateActivityController from './components/activities/CreateActivityController';
import ActivitiesController from './components/activities/ActivitiesController';
import ViewActivityController from './components/activities/ViewActivityController';
import EditActivityController from './components/activities/EditActivityController';
import ToolsController from './components/tools/ToolsController';
import CreateToolController from './components/tools/CreateToolController';
import EditToolController from './components/tools/EditToolController';
import ViewToolController from './components/tools/ViewToolController';
import CompetenciesController from './components/competencies/CompetenciesController';
import OrganisationsController from './components/admin/organisations/OrganisationsController';
import EditOrganisationController from './components/admin/organisations/EditOrganisationController';
import CreateOrganisationController from './components/admin/organisations/CreateOrganisationController';
import ThemesController from './components/admin/ThemesController';
import AdminCoursesController from './components/admin/AdminCoursesController';
import UserRoles from './components/admin/UserRoles';
import ReportsController from './components/reports/ReportsController';
import ViewReportController from './components/reports/ViewReportController';
import FeatureArticle from './components/features/FeatureArticle';
import FeatureGoogleForm from './components/features/FeatureGoogleForm';
import SkillsProfilesController from './components/skillsProfiles/SkillsProfilesController';
import SkillsProfileController from './components/skillsProfiles/SkillsProfileController';
import ImportSkillsProfilesController from './components/skillsProfiles/ImportSkillsProfilesController';
import CentresController from './components/centres/CentresController';
import CreateCentreController from './components/centres/CreateCentreController';
import EditCentreController from './components/centres/EditCentreController';
import ViewCentreController from './components/centres/ViewCentreController';
import CreateDepartmentController from './components/departments/CreateDepartmentController';
import EditDepartmentController from './components/departments/EditDepartmentController';
import CreatePositionController from './components/positions/CreatePositionController';
import EditPositionController from './components/positions/EditPositionController';
import CreateResourceController from './components/resources/CreateResourceController';
import ResourcesController from './components/resources/ResourcesController';
import ViewResourceController from './components/resources/ViewResourceController';
import EditResourceController from './components/resources/EditResourceController';
import SurveyController from './components/survey/SurveyController';
import WefController from './components/wef/WefController';
import FrameworksController from './components/frameworks/FrameworksController';
import ImportFrameworkController from './components/frameworks/ImportFrameworkController';
import EditFrameworkController from './components/frameworks/EditFrameworkController';
import DiscussionController from './components/discussion/DiscussionController';
import ViewDiscussionController from './components/discussion/ViewDiscussionController';
import EditDiscussionController from './components/discussion/EditDiscussionController';
import MyCommunityController from './components/myCommunity/MyCommunityController';
import ManageRoles from './components/admin/roles/ManageRoles';
import EditRoleController from './components/admin/roles/EditRoleController';
import AddRoleController from './components/admin/roles/AddRoleController';
import ProfileGroupsController from './components/profileGroups/ProfileGroupsController';
import EditProfileGroupController from './components/profileGroups/EditProfileGroupController';
import CreateProfileGroupController from './components/profileGroups/CreateProfileGroupController';
import LDCoursesController from './components/ldCourses/LDCoursesController';
import CreateLDCourseController from './components/ldCourses/CreateLDCourseController';
import EditLDCourseController from './components/ldCourses/EditLDCourseController';
import ViewLDCourseController from './components/ldCourses/ViewLDCourseController';
import CareerPathwayController from './components/careerPathway/CareerPathwayController';
import ManageReports from './components/admin/reports/ManageReports';
import CreateReportController from './components/admin/reports/CreateReportController';
import EditReportController from './components/admin/reports/EditReportController';
import ImpersonateController from './components/impersonate/ImpersonateController';
import ManageGroups from './components/admin/reports/ManageGroups';
import CreateGroupController from './components/admin/reports/CreateGroupController';
import EditGroupController from './components/admin/reports/EditGroupController';
import MentorsController from './components/mentors/MentorsController';
import ViewMentorController from './components/mentors/ViewMentorController';
import CreateMentorController from './components/mentors/CreateMentorController';
import EditMentorController from './components/mentors/EditMentorController';
import ManageMenus from './components/admin/menu/ManageMenus';
import CreateMenuController from './components/admin/menu/CreateMenuController';
import EditMenuController from './components/admin/menu/EditMenuController';
import UserOrganisationsController from './components/admin/userOrganisations/UserOrganisationsController';
import AddUserOrganisationController from './components/admin/userOrganisations/AddUserOrganisationController';
import EditUserOrganisationController from './components/admin/userOrganisations/EditUserOrganisationController';
import TagsController from './components/admin/tags/TagsController';
import AddTagController from './components/admin/tags/AddTagController';
import EditTagController from './components/admin/tags/EditTagController';
import StudentsController from './components/students/StudentsController';
import CreateStudentController from './components/students/CreateStudentController';
import EditStudentController from './components/students/EditStudentController';
import ViewStudentController from './components/students/ViewStudentController';
import StudentProjectsController from './components/studentProjects/StudentProjectsController';
import CreateStudentProjectController from './components/studentProjects/CreateStudentProjectController';
import EditStudentProjectController from './components/studentProjects/EditStudentProjectController';
import ViewStudentProjectController from './components/studentProjects/ViewStudentProjectController';
import EnquiriesController from './components/studentProjects/EnquiriesController';
import InsightsController from './components/insights/InsightsController';
import CreateInsightController from './components/insights/CreateInsightController';
import EditInsightController from './components/insights/EditInsightController';
import ViewInsightController from './components/insights/ViewInsightController';
import ExpertsController from './components/experts/ExpertsController';
import ViewExpertController from './components/experts/ViewExpertController';
import CreateExpertController from './components/experts/CreateExpertController';
import EditExpertController from './components/experts/EditExpertController';
import MenteesController from './components/mentees/MenteesController';
import CreateMenteeController from './components/mentees/CreateMenteeController';
import ViewMenteeController from './components/mentees/ViewMenteeController';
import EditMenteeController from './components/mentees/EditMenteeController';
import TeamsController from './components/teams/TeamsController';
import CreateTeamController from './components/teams/CreateTeamController';
import EditTeamController from './components/teams/EditTeamController';
import ViewTeamController from './components/teams/ViewTeamController';
import TeamSkillsSummaryController from './components/teamSkillsSummary/TeamSkillsSummaryController';
import BusinessProfilesController from './components/businessProfiles/BusinessProfilesController';
import CreateBusinessProfileController from './components/businessProfiles/CreateBusinessProfileController';
import EditBusinessProfileController from './components/businessProfiles/EditBusinessProfileController';
import ViewBusinessProfileController from './components/businessProfiles/ViewBusinessProfileController';
import ContentController from './components/content/ContentController';
import CreateContentController from './components/content/CreateContentController';
import EditContentController from './components/content/EditContentController';
import ViewContentController from './components/content/ViewContentController';
import CollaborationsController from './components/collaborations/CollaborationsController';
import CreateCollaborationController from './components/collaborations/CreateCollaborationController';
import EditCollaborationController from './components/collaborations/EditCollaborationController';
import ViewCollaborationController from './components/collaborations/ViewCollaborationController';
import CollaborationEnquiriesController from './components/collaborations/CollaborationEnquiriesController';
import AcceptCollaborationInviteController from './components/collaborations/AcceptCollaborationInviteController';
import ViewCollaborationDiscussionController from './components/collaborations/ViewDiscussionController';
import EditCollaborationDiscussionController from './components/collaborations/EditDiscussionController';
import ViewCLO_CourseController from './components/cloCourses/ViewCLO_CourseController';
import CLO_CoursesController from './components/cloCourses/CLO_CoursesController';
import CreateCLO_CourseController from './components/cloCourses/CreateCLO_CourseController';
import EditCLO_CourseController from './components/cloCourses/EditCLO_CourseController';
import UIThemesController from './components/themes/ThemesController';
import AddUIThemeController from './components/themes/AddThemeController';
import EditUIThemeController from './components/themes/EditThemeController';
import ViewUIThemeController from './components/themes/ViewThemeController';
import UpgradeController from './components/upgrade/UpgradeController';
import UpgradeCollaborationController from './components/upgrade/UpgradeCollaborationController';
import UpgradeCloController from './components/upgrade/UpgradeCloController';
import UpgradeCataloguesController from './components/upgrade/UpgradeCataloguesController';
import UpgradeOffersController from './components/upgrade/UpgradeOffersController';
import UpgradeIpController from './components/upgrade/UpgradeIpController';
import UpgradeTeamSkillsController from './components/upgrade/UpgradeTeamSkillsController';
import PricingController from './components/upgrade/PricingController';
import PublishAdmin from './components/admin/publishAdmin/PublishAdmin';
import MicroLearnController from './components/microlearn/MicroLearnController';
import ViewMicroLearnController from './components/microlearn/ViewMicroLearnController';
import CreateMicroLearnController from './components/microlearn/CreateMicroLearnController';
import EditMicroLearnController from './components/microlearn/EditMicroLearnController';

import QuestionnaireLayout from './components/questionnaires/QuestionnaireLayout';
import APS_Registration_Confirmation from './components/questionnaires/APS_registration_confirmation';
import Landing from './components/landing/Landing';

/* Stripe */
// import SubscriptionController from './components/subscription/SubscriptionController';
import ProductsController from './components/admin/stripeProducts/ProductsController';
import EditProductController from './components/admin/stripeProducts/EditProductController';
import CreateProductController from './components/admin/stripeProducts/CreateProductController';
import SubscriptionCompleteController from './components/subscription/SubscriptionCompleteController';
import ReportLayout from './components/ReportLayout';
import IndividualSkillsProfile from './components/reports/sfia/IndividualSkillsProfile';
import GeneralCompetencyAssessment from './components/reports/clo/GeneralCompetencyAssessment';

// const StandardRoute = ({ path, func, component: Component }) => (
// 	<Route exact path={path} render={props => 
// 		<Layout function={func}>
// 			<Component {...props} />
// 		</Layout>
// 	} />
// );

const UpgradeRoute = ({ path, page, component: Component }) => (
	<Route exact path={path} render={props => 
		<Layout section="upgrade">
			<UpgradeController 
				{...props}
				page={page}
				content={Component}
			/>
		</Layout>
	} />
);

export default () => (
	<Switch>
		<Route exact path="/">
			<Layout section="home">
				<HomeWrapper />
			</Layout>
		</Route>
		<Route exact path="/login" render={props =>
			<LoginLayout timeout={get(props.history.location, "state.timeout", false)}>
				<Login />
			</LoginLayout>
		} />
		<Route exact path="/register-path">
			<RegistrationPath />
		</Route>
		<Route exact path="/register" render={props =>
			<LoginLayout>
				<RegistrationRouter {...props} />
			</LoginLayout>
		} />
		<Route exact path="/collaborations/accept-invite" render={props => 
			<AnonymousLayout>
				<AcceptCollaborationInviteController {...props} />
			</AnonymousLayout>
		} />
		<Route exact path="/profile" render={props =>
			<Layout section="base">
				<ProfileController {...props} />
			</Layout>
		} />
		<Route exact path="/search" render={props =>
			<Layout section="base">
				<SearchController {...props} />
			</Layout>
		} />
		<Route exact path="/problems" render={props => 
			<Layout>
				<ProblemsController {...props} />
			</Layout>
		} />
		<Route exact path="/problems/create" render={props => 
			<Layout function="ProblemSubmit">
				<CreateProblemController {...props} />
			</Layout>
		} />
		<Route exact path="/problems/:problemId?" render={props => 
			<Layout>
				<ViewProblemController {...props} />
			</Layout>
		} />
		<Route exact path="/problems/edit/:problemId?" render={props => 
			<Layout>
				<EditProblemController {...props} />
			</Layout>
		} />
		<Route exact path="/problems/by-user/:userId?" render={props => 
			<Layout>
				<UserProblemsController {...props} />
			</Layout>
		} />
		<Route exact path="/problems/stats/:problemId?" render={props => 
			<Layout>
				<ProblemStatsController {...props} />
			</Layout>
		} />
		<Route exact path="/events" render={props => 
			<Layout>
				<EventsController {...props} />
			</Layout>
		} />
		<Route exact path="/events/create" render={props => 
			<Layout function="EventSubmit">
				<CreateEventController {...props} />
			</Layout>
		} />
		<Route exact path="/events/request" render={props => 
			<Layout function="EventRequest">
				<RequestEventController {...props} />
			</Layout>
		} />
		<Route exact path="/events/:eventId?" render={props => 
			<Layout>
				<ViewEventController {...props} />
			</Layout>
		} />
		<Route exact path="/events/edit/:eventId?" render={props => 
			<Layout>
				<EditEventController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/tenants">
			<Layout requiredRole="Global Administrator">
				<TenantsController />
			</Layout>
		</Route>admin
		<Route exact path="/admin/tenants/add" render={props => 
			<Layout requiredRole="Global Administrator">
				<AddTenantController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/tenants/:tenantId?" render={props => 
			<Layout requiredRole="Global Administrator">
				<EditTenantController key={props.match.params.tenantId} {...props} />
			</Layout>
		} />
		<Route exact path="/users">
			<Layout section="admin" function="UserView">
				<UsersController />
			</Layout>
		</Route>
		<Route exact path="/users/activity-logs">
			<Layout section="admin" requiredRole="Global Administrator">
				<ActivityLogsController />
			</Layout>
		</Route>
		<Route exact path="/users/add" render={props => 
			<Layout section="admin" function="UserSubmit">
				<AddUserController {...props} />
			</Layout>
		} />
		<Route exact path="/users/:userId?" render={props => 
			<Layout section="admin" function="UserEdit">
				<EditUserController key={props.match.params.userId} {...props} />
			</Layout>
		} />
		<Route exact path="/users/stats/:userId?" render={props => 
			<Layout section="admin">
				<UserStatsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/roles">
			<Layout function="RolesView">
				<ManageRoles />
			</Layout>
		</Route>
		<Route exact path="/admin/roles/add" render={props => 
			<Layout function="RolesView">
				<AddRoleController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/roles/:roleId?" render={props => 
			<Layout>
				<EditRoleController key={props.match.params.roleId} {...props} />
			</Layout>
		} />
		<Route exact path="/ui-themes">
			<Layout section="uiThemes" function="ApplicationThemeView">
				<UIThemesController />
			</Layout>
		</Route>
		<Route exact path="/ui-themes/create" render={props => 
			<Layout section="uiThemes" function="ApplicationThemeCreate">
				<AddUIThemeController {...props} />
			</Layout>
		} />
		<Route exact path="/ui-themes/:themeId?" render={props => 
			<Layout section="uiThemes">
				<ViewUIThemeController {...props} />
			</Layout>
		} />		
		<Route exact path="/ui-themes/edit/:themeId?" render={props => 
			<Layout section="uiThemes">
				<EditUIThemeController {...props} />
			</Layout>
		} />	
{/* 		
		<StandardRoute path="/offers" component={OffersController} />
		<StandardRoute path="/offers/create" function="OfferSubmit" component={CreateOfferController} />
		<StandardRoute path="/offers/edit/:offerId?" component={EditOfferController} />
		<StandardRoute path="/offers/applications" component={ApplicationsController} />
		<StandardRoute path="/offers/applications/:applicationId?" component={ViewApplicationController} />
		<StandardRoute path="/offers/:offerId?" component={ViewOfferController} />
		<StandardRoute path="/offers/:offerId?/apply" component={NewApplicationController} /> */}

		<Route exact path="/offers" render={props => 
			<Layout>
				<OffersController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/create" render={props => 
			<Layout function="OfferSubmit">
				<CreateOfferController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/edit/:offerId?" render={props => 
			<Layout>
				<EditOfferController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/applications" render={props =>
			<Layout>
				<ApplicationsController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/applications/:applicationId?" render={props =>
			<Layout>
				<ViewApplicationController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/:offerId?" render={props => 
			<Layout>
				<ViewOfferController {...props} />
			</Layout>
		} />
		<Route exact path="/offers/:offerId?/apply" render={props =>
			<Layout>
				<NewApplicationController {...props} />
			</Layout>
		} />

		<Route exact path="/research-match" render={props => 
			<Layout section="offers">
				<BusinessChallengesController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/create" render={props => 
			<Layout function="OfferSubmit" section="offers">
				<CreateBusinessChallengeController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/edit/:offerId?" render={props => 
			<Layout section="offers">
				<EditBusinessChallengeController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/applications" render={props =>
			<Layout section="offers">
				<BusinessChallengeApplicationsController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/applications/:applicationId?" render={props =>
			<Layout section="offers">
				<ViewBusinessChallengeApplicationController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/:offerId?" render={props => 
			<Layout section="offers">
				<ViewBusinessChallengeController {...props} />
			</Layout>
		} />
		<Route exact path="/research-match/:offerId?/apply" render={props =>
			<Layout section="offers">
				<NewBusinessChallengeApplicationController {...props} />
			</Layout>
		} />

		<Route exact path="/users/:userId?/applications" render={props =>
			<Layout section="admin" function="UserView">
				<UserApplicationsController {...props} />
			</Layout>
		} />
		<Route exact path="/ip" render={props =>
			<Layout>
				<IPController {...props} />
			</Layout>
		} />
		<Route exact path="/ip/create" render={props =>
			<Layout function="IpSubmit">
				<RegisterIPController {...props} />
			</Layout>
		} />
		<Route exact path="/ip/enquiries" render={props => 
			<Layout function="IPEnquiryAdmin">
				<IPEnquiriesController {...props} />
			</Layout>
		} />
		<Route exact path="/ip/:ipId?" render={props => 
			<Layout>
				<ViewIPController {...props} />
			</Layout>
		} />
		<Route exact path="/ip/edit/:ipId?" render={props =>
			<Layout>
				<EditIPController {...props} />
			</Layout>
		} />
		<Route exact path="/profile/submissions">
			<Layout section="base">
				<SubmissionsController />
			</Layout>
		</Route>
		<Route exact path="/register/confirm-email" component={ConfirmEmail} />
		<Route exact path="/account/reset-password">
			<LoginLayout>
				<PasswordReset />
			</LoginLayout>
		</Route>
		<Route exact path="/account/confirm-reset-password" render={props => 
			<LoginLayout>
				<ConfirmPasswordReset {...props} />
			</LoginLayout>
		} />
		<Route exact path="/about">
			<Layout section="base">
				<About />
			</Layout>
		</Route>
		<Route exact path="/about/funding">
			<Layout section="base">
				<Funding />
			</Layout>
		</Route>
		<Route exact path="/copyright">
			<Layout>
				<Copyright />
			</Layout>
		</Route>
		<Route exact path="/disclaimer">
			<Layout>
				<Disclaimer />
			</Layout>
		</Route>
		<Route exact path="/help">
			<Layout section="base">
				<Help />
			</Layout>
		</Route>
		<Route exact path="/contact-us">
			<Layout section="contact">
				<ContactUs />
			</Layout>
		</Route>
		<Route exact path="/admin/settings">
			<Layout function="SettingsView">
				<Settings />
			</Layout>
		</Route>
		<Route exact path="/courses" render={props => 
			<Layout>
				<CoursesController {...props} />
			</Layout>
		} />
		<Route exact path="/courses/request" render={props => 
			<Layout>
				<CustomCourse {...props} />
			</Layout>
		} />
		<Route exact path="/courses/create" render={props => 
			<Layout function="CourseSubmit">
				<CreateCourseController {...props} />
			</Layout>
		} />
		<Route exact path="/courses/:courseId?" render={props => 
			<Layout>
				<ViewCourseController {...props} />
			</Layout>
		} />
		<Route exact path="/courses/edit/:courseId?" render={props => 
			<Layout function="CourseEdit">
				<EditCourseController {...props} />
			</Layout>
		} />
		<Route exact path="/courses/suggest/:profileId?" render={props => 
			<Layout>
				<SuggestCoursesController {...props} />
			</Layout>
		} />
		<Route exact path="/clo-courses" render={props => 
			<Layout section="cloCourses" function="CLO_CourseView">
				<CLO_CoursesController {...props} />
			</Layout>
		} />
		<Route exact path="/clo-courses/create" render={props => 
			<Layout section="cloCourses" function="CLO_CourseSubmit">
				<CreateCLO_CourseController {...props} />
			</Layout>
		} />
		<Route exact path="/clo-courses/:courseId?" render={props => 
			<Layout section="cloCourses" function="CLO_CourseView">
				<ViewCLO_CourseController {...props} />
			</Layout>
		} />
		<Route exact path="/clo-courses/edit/:courseId?" render={props => 
			<Layout section="cloCourses" function="CLO_CourseEdit">
				<EditCLO_CourseController {...props} />
			</Layout>
		} />
		<Route exact path="/skills" render={props => 
			<Layout>
				<PassportController {...props} />
			</Layout>
		} />
		<Route exact path="/skills/competencies" render={props =>
			<Layout>
				<CompetenciesController mode="all" {...props} />
			</Layout>
		} />
		<Route exact path="/skills/primary" render={props =>
			<Layout>
				<CompetenciesController mode="primary" {...props} />
			</Layout>
		} />
		<Route exact path="/skills/secondary" render={props =>
			<Layout>
				<CompetenciesController mode="secondary" {...props} />
			</Layout>
		} />
		<Route exact path="/team-skills-summary" render={props => 
			<Layout section="teamSkillsSummary">
				<TeamSkillsSummaryController {...props} />
			</Layout>
		} />
		<Route exact path="/team-skills" render={props => 
			<Layout section="teamSkills">
				<CompetenciesController mode="team" {...props} />
			</Layout>
		} />
		<Route exact path="/business-units" render={props => 
			<Layout section="admin" function="DepartmentView">
				<DepartmentsController {...props} />
			</Layout>
		} />
		<Route exact path="/business-units/create" render={props => 
			<Layout section="admin" function="DepartmentView"> 
				<CreateDepartmentController {...props} />
			</Layout>
		} />
		<Route exact path="/business-units/edit/:departmentId?" render={props => 
			<Layout section="admin" function="DepartmentEdit">
				<EditDepartmentController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/positions" render={props => 
			<Layout function="PositionView">
				<PositionsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/positions/create" render={props => 
			<Layout function="PositionView">
				<CreatePositionController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/positions/:positionId?" render={props => 
			<Layout function="PositionEdit">
				<EditPositionController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/dashboard" render={props => 
			<Layout function="OrganisationsView">
				<DashboardController {...props} />
			</Layout>
		} />
		<Route exact path="/academics" render={props =>
			<Layout>
				<AcademicsController {...props} />
			</Layout>
		} />
		<Route exact path="/academics/create" render={props => 
			<Layout function="AcademicSubmit">
				<CreateAcademicController {...props} />
			</Layout>
		} />
		<Route exact path="/academics/:academicId?" render={props => 
			<Layout>
				<ViewAcademicController {...props} />
			</Layout>
		} />
		<Route exact path="/academics/edit/:academicId?" render={props => 
			<Layout function="AcademicEdit">
				<EditAcademicController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/communities">
			<Layout function="CommunityView">
				<CommunitiesController />
			</Layout>
		</Route>
		<Route exact path="/admin/communities/create" render={props => 
			<Layout function="CommunitySubmit">
				<CreateCommunityController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/communities/edit/:communityId?" render={props => 
			<Layout function="CommunityEdit">
				<EditCommunityController {...props} />
			</Layout>
		} />
		<Route exact path="/activities" render={props =>
			<Layout>
				<ActivitiesController {...props} />
			</Layout>
		} />
		<Route exact path="/activities/create" render={props => 
			<Layout anyFunctions={["ActivitySubmit", "LearningDesignActivitiesAdd"]}>
				<CreateActivityController {...props} />
			</Layout>
		} />
		<Route exact path="/activities/:activityId?" render={props => 
			<Layout>
				<ViewActivityController {...props} />
			</Layout>
		} />
		<Route exact path="/activities/edit/:activityId?" render={props => 
			<Layout function="ActivityEdit">
				<EditActivityController {...props} />
			</Layout>
		} />
		<Route exact path="/tools" render={props =>
			<Layout>
				<ToolsController {...props} />
			</Layout>
		} />
		<Route exact path="/tools/create" render={props => 
			<Layout>
				<CreateToolController {...props} />
			</Layout>
		} />
		<Route exact path="/tools/:toolId?" render={props => 
			<Layout>
				<ViewToolController {...props} />
			</Layout>
		} />
		<Route exact path="/tools/edit/:toolId?" render={props => 
			<Layout>
				<EditToolController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/courses" render={props => 
			<Layout function="AdminCoursesView">
				<AdminCoursesController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/courses/create" render={props => 
			<Layout functions={["AdminCoursesView", "CourseSubmit"]}>
				<CreateCourseController fromAdmin {...props} />
			</Layout>
		} />
		<Route exact path="/admin/courses/:courseId?" render={props => 
			<Layout function="AdminCoursesView">
				<ViewCourseController fromAdmin {...props} />
			</Layout>
		} />
		<Route exact path="/admin/courses/edit/:courseId?" render={props => 
			<Layout functions={["AdminCoursesView", "CourseEdit"]}>
				<EditCourseController fromAdmin {...props} />
			</Layout>
		} />
		<Route exact path="/features">
			<Layout section="admin" function="FeatureView">
				<FeaturesController />
			</Layout>
		</Route>
		<Route exact path="/features/create" render={props => 
			<Layout section="admin" function="FeatureSubmit">
				<CreateFeatureController {...props} />
			</Layout>
		} />
		<Route exact path="/features/edit/:featureId?" render={props => 
			<Layout section="admin" function="FeatureEdit">
				<EditFeatureController key={props.match.params.featureId} {...props} />
			</Layout>
		} />
		<Route exact path="/articles/:featureId?" render={props => 
			<Layout section="base">
				<FeatureArticle key={props.match.params.featureId} {...props} />
			</Layout>
		} />
		<Route exact path="/forms/:featureId?" render={props => 
			<Layout section="base">
				<FeatureGoogleForm key={props.match.params.featureId} {...props} />
			</Layout>
		} />
		<Route exact path="/admin/organisations" render={props => 
			<Layout function="OrganisationsView">
				<OrganisationsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/institutions" render={props => 
			<Layout function="OrganisationsView">
				<OrganisationsController {...props} institutionsOnly />
			</Layout>
		} />
		<Route exact path="/admin/organisations/create" render={props => 
			<Layout>
				<CreateOrganisationController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/organisations/edit/:organisationId?" render={props => 
			<Layout>
				<EditOrganisationController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/themes" render={props => 
			<Layout function="ThemesView">
				<ThemesController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/frameworks" render={props => 
			<Layout function="ViewFrameworks">
				<FrameworksController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/frameworks/import" render={props => 
			<Layout function="ViewFrameworks">
				<ImportFrameworkController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/frameworks/:frameworkId?" render={props => 
			<Layout function="ViewFrameworks">
				<EditFrameworkController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/user-roles" render={props =>
			<Layout>
				<UserRoles {...props} />
			</Layout>
		} />
		<Route exact path="/admin/tags" render={props => 
			<Layout function="TagsAdmin">
				<TagsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/tags/add" render={props => 
			<Layout function="TagsAdmin">
				<AddTagController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/tags/:tagId?" render={props => 
			<Layout function="TagsAdmin">
				<EditTagController {...props} />
			</Layout>
		} />
		<Route exact path="/reports/view/sfia/individual-skills-profile" render={props => 
			<Layout section="sfiaReport">
				<IndividualSkillsProfile {...props} />
			</Layout>
		} />
		<Route exact path="/reports/view/sfia/individual-skills-profile/pdf" render={props => 
			<ReportLayout>
				<IndividualSkillsProfile {...props} isReportLayout />
			</ReportLayout>
		} />
		<Route exact path="/reports/view/clo/general-competency-assessment" render={props => 
			<Layout section="cloReport">
				<GeneralCompetencyAssessment {...props} />
			</Layout>
		} />
		<Route exact path="/reports/view/clo/general-competency-assessment/pdf" render={props => 
			<ReportLayout>
				<GeneralCompetencyAssessment {...props} isReportLayout />
			</ReportLayout>
		} />
		<Route exact path="/reports" render={props => 
			<Layout>
				<ReportsController {...props} />
			</Layout>
		} />
		<Route exact path="/reports/view/:reportId" render={props => 
			<Layout section="base">
				<ViewReportController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/skills-profiles" render={props => 
			<Layout function="SkillsProfilesView">
				<SkillsProfilesController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/skills-profile/:skillsProfileId?" render={props => 
			<Layout>
				<SkillsProfileController key={props.match.params.skillsProfileId} {...props} />
			</Layout>
		} />
		<Route exact path="/admin/profile-groups" render={props => 
			<Layout function="ProfileGroupsView">
				<ProfileGroupsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/profile-groups/create" render={props => 
			<Layout function="ProfileGroupsView">
				<CreateProfileGroupController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/profile-groups/:groupId?" render={props => 
			<Layout function="ProfileGroupsEdit">
				<EditProfileGroupController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/import-skills-profiles" render={props => 
			<Layout>
				<ImportSkillsProfilesController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/report-groups" render={props => 
			<Layout function="AdminReportsView">
				<ManageGroups {...props} />
			</Layout>
		} />
		<Route exact path="/admin/report-groups/add" render={props => 
			<Layout function="AdminReportsView">
				<CreateGroupController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/report-groups/:groupId?" render={props => 
			<Layout function="AdminReportsView">
				<EditGroupController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/reports" render={props => 
			<Layout function="AdminReportsView">
				<ManageReports {...props} />
			</Layout>
		} />
		<Route exact path="/admin/reports/add" render={props => 
			<Layout function="AdminReportsView">
				<CreateReportController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/reports/:reportId?" render={props => 
			<Layout function="AdminReportsView">
				<EditReportController {...props} />
			</Layout>
		} />
		<Route exact path="/research-centres" render={props =>
			<Layout section="centres">
				<CentresController {...props} />
			</Layout>
		} />
		<Route exact path="/research-centres/create" render={props => 
			<Layout section="centres" function="ResearchCentreSubmit">
				<CreateCentreController {...props} />
			</Layout>
		} />
		<Route exact path="/research-centres/:centreId?" render={props => 
			<Layout section="centres">
				<ViewCentreController {...props} />
			</Layout>
		} />
		<Route exact path="/research-centres/edit/:centreId?" render={props => 
			<Layout section="centres" function="ResearchCentreEdit">
				<EditCentreController {...props} />
			</Layout>
		} />
		<Route exact path="/resources" render={props =>
			<Layout>
				<ResourcesController {...props} />
			</Layout>
		} />
		<Route exact path="/resources/create" render={props => 
			<Layout function="ResourceSubmit">
				<CreateResourceController {...props} />
			</Layout>
		} />
		<Route exact path="/resources/:resourceId?" render={props => 
			<Layout>
				<ViewResourceController {...props} />
			</Layout>
		} />
		<Route exact path="/resources/edit/:resourceId?" render={props => 
			<Layout>
				<EditResourceController {...props} />
			</Layout>
		} />
		<Route exact path="/survey" render={props => 
			<Layout>
				<SurveyController {...props} />
			</Layout>
		} />
		<Route exact path="/wef" render={props => 
			<Layout>
				<WefController {...props} />
			</Layout>
		} />
		<Route exact path="/discussion" render={props =>
			<Layout>
				<DiscussionController {...props} />
			</Layout>
		} />
		<Route exact path="/discussion/:discussionId?" render={props =>
			<Layout>
				<ViewDiscussionController {...props} />
			</Layout>
		} />
		<Route exact path="/discussion/edit/:discussionId" render={props =>
			<Layout>
				<EditDiscussionController {...props} />
			</Layout>
		} />
		<Route exact path="/my-community" render={props =>
			<Layout section="myCommunity">
				<MyCommunityController {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/tools" render={props =>
			<Layout section="myCommunityTools">
				<ToolsController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/tools/create" render={props => 
			<Layout section="myCommunityTools" function="CommunityToolsAdd">
				<CreateToolController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/tools/:toolId?" render={props => 
			<Layout section="myCommunityTools">
				<ViewToolController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/tools/edit/:toolId?" render={props => 
			<Layout section="myCommunityTools">
				<EditToolController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/resources" render={props =>
			<Layout section="myCommunityResources">
				<ResourcesController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/resources/create" render={props => 
			<Layout section="myCommunityResources" function="CommunityResourcesAdd">
				<CreateResourceController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/resources/:resourceId?" render={props => 
			<Layout section="myCommunityResources">
				<ViewResourceController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/resources/edit/:resourceId?" render={props => 
			<Layout section="myCommunityResources">
				<EditResourceController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/events" render={props =>
			<Layout section="myCommunityEvents">
				<EventsController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/events/create" render={props => 
			<Layout section="myCommunityEvents" function="CommunityEventsAdd">
				<CreateEventController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/events/request" render={props => 
			<Layout section="myCommunityEvents" function="EventsRequest">
				<RequestEventController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/events/:eventId?" render={props => 
			<Layout section="myCommunityEvents">
				<ViewEventController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/events/edit/:eventId?" render={props => 
			<Layout section="myCommunityEvents">
				<EditEventController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/problems" render={props =>
			<Layout section="myCommunityProblems">
				<ProblemsController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/problems/create" render={props => 
			<Layout section="myCommunityProblems" function="CommunityProblemsAdd">
				<CreateProblemController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/problems/:problemId?" render={props => 
			<Layout section="myCommunityProblems">
				<ViewProblemController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/problems/edit/:problemId?" render={props => 
			<Layout section="myCommunityProblems">
				<EditProblemController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/discussion" render={props =>
			<Layout section="myCommunityDiscussion">
				<DiscussionController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/discussion/:discussionId?" render={props =>
			<Layout section="myCommunityDiscussion">
				<ViewDiscussionController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/discussion/edit/:discussionId" render={props =>
			<Layout section="myCommunityDiscussion">
				<EditDiscussionController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/course-design" render={props =>
			<Layout section="learningDesign">
				<LDCoursesController {...props} />
			</Layout>
		} />
		<Route exact path="/course-design/create" render={props => 
			<Layout section="learningDesign">
				<CreateLDCourseController key={props.match.params.id} {...props} />
			</Layout>
		} />
		<Route exact path="/course-design/:id?" render={props => 
			<Layout section="learningDesign">
				<ViewLDCourseController key={props.match.params.id} {...props} />
			</Layout>
		} />
		<Route exact path="/course-design/edit/:id?" render={props => 
			<Layout section="learningDesign">
				<EditLDCourseController key={props.match.params.id} {...props} />
			</Layout>
		} />
		<Route exact path="/career-pathway" render={props =>
			<Layout section="careerPathway">
				<CareerPathwayController {...props} />
			</Layout>
		} />
		<Route exact path="/impersonate" render={props =>
			<Layout section="impersonate">
				<ImpersonateController {...props} />
			</Layout>
		} />
		{/* Stripe */}
		<Route exact path="/subscription" render={props =>
			<Layout>
				{/* <SubscriptionController {...props} /> */}
				<PricingController />
			</Layout>
		} />
		<Route exact path="/subscription-complete" render={props =>
			<Layout section="subscriptionComplete">
				<SubscriptionCompleteController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/products">
			<Layout>
				<ProductsController />
			</Layout>
		</Route>
		<Route exact path="/admin/products/create" render={props => 
			<Layout>
				<CreateProductController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/products/edit/:id?" render={props => 
			<Layout>
				<EditProductController key={props.match.params.id} {...props} />
			</Layout>
		} />
		<Route exact path="/mentors" render={props =>
			<Layout>
				<MentorsController {...props} />
			</Layout>
		} />
		<Route exact path="/mentors/create" render={props => 
			<Layout function="MentorSubmit">
				<CreateMentorController {...props} />
			</Layout>
		} />
		<Route exact path="/mentors/:mentorId?" render={props => 
			<Layout>
				<ViewMentorController {...props} />
			</Layout>
		} />
		<Route exact path="/mentors/edit/:mentorId?" render={props => 
			<Layout>
				<EditMentorController {...props} />
			</Layout>
		} />
		<Route exact path="/mentees" render={props =>
			<Layout>
				<MenteesController {...props} />
			</Layout>
		} />
		<Route exact path="/mentees/create" render={props => 
			<Layout function="MenteeSubmit">
				<CreateMenteeController {...props} />
			</Layout>
		} />
		<Route exact path="/mentees/:menteeId?" render={props => 
			<Layout>
				<ViewMenteeController {...props} />
			</Layout>
		} />
		<Route exact path="/mentees/edit/:menteeId?" render={props => 
			<Layout>
				<EditMenteeController {...props} />
			</Layout>
		} />
		<Route exact path="/teams" render={props =>
			<Layout>
				<TeamsController {...props} />
			</Layout>
		} />
		<Route exact path="/teams/create" render={props => 
			<Layout anyFunction={["TeamLeader", "DepartmentEdit"]}>
				<CreateTeamController {...props} />
			</Layout>
		} />
		<Route exact path="/teams/:id?" render={props => 
			<Layout>
				<ViewTeamController {...props} />
			</Layout>
		} />
		<Route exact path="/teams/edit/:id?" render={props => 
			<Layout>
				<EditTeamController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/menu">
			<Layout requiredFunction="AdminMenuView">
				<ManageMenus />
			</Layout>
		</Route>
		<Route exact path="/admin/menu/create" render={props => 
			<Layout requiredFunction="AdminMenuView">
				<CreateMenuController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/menu/:menuId?" render={props => 
			<Layout requiredFunction="AdminMenuView">
				<EditMenuController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/user-organisations" render={props => 
			<Layout requiredFunction="UserOrganisationView">
				<UserOrganisationsController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/user-organisations/add" render={props => 
			<Layout requiredFunction="UserOrganisationEdit">
				<AddUserOrganisationController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/user-organisations/:userOrganisationId?" render={props => 
			<Layout requiredFunction="UserOrganisationEdit">
				<EditUserOrganisationController {...props} />
			</Layout>
		} />
		<Route exact path="/students" render={props =>
			<Layout>
				<StudentsController {...props} />
			</Layout>
		} />
		<Route exact path="/students/create" render={props => 
			<Layout function="StudentSubmit">
				<CreateStudentController {...props} />
			</Layout>
		} />
		<Route exact path="/students/:studentId?" render={props => 
			<Layout>
				<ViewStudentController {...props} />
			</Layout>
		} />
		<Route exact path="/students/edit/:studentId?" render={props => 
			<Layout function="StudentEdit">
				<EditStudentController {...props} />
			</Layout>
		} />
		<Route exact path="/business-challenges" render={props =>
			<Layout section="studentProjects">
				<StudentProjectsController {...props} />
			</Layout>
		} />
		<Route exact path="/business-challenges/create" render={props => 
			<Layout section="studentProjects" function="StudentProjectSubmit">
				<CreateStudentProjectController {...props} />
			</Layout>
		} />
		<Route exact path="/business-challenges/enquiries" render={props => 
			<Layout section="studentProjects" function="EnquiryAdmin">
				<EnquiriesController {...props} />
			</Layout>
		} />
		<Route exact path="/business-challenges/:projectId?" render={props => 
			<Layout section="studentProjects">
				<ViewStudentProjectController {...props} />
			</Layout>
		} />
		<Route exact path="/business-challenges/edit/:projectId?" render={props => 
			<Layout section="studentProjects" function="StudentProjectEdit">
				<EditStudentProjectController {...props} />
			</Layout>
		} />
		<Route exact path="/insights" render={props =>
			<Layout>
				<InsightsController {...props} />
			</Layout>
		} />
		<Route exact path="/insights/create" render={props => 
			<Layout function="InsightSubmit">
				<CreateInsightController {...props} />
			</Layout>
		} />
		<Route exact path="/insights/:insightId?" render={props => 
			<Layout>
				<ViewInsightController {...props} />
			</Layout>
		} />
		<Route exact path="/insights/edit/:insightId?" render={props => 
			<Layout>
				<EditInsightController {...props} />
			</Layout>
		} />
		<Route exact path="/experts" render={props =>
			<Layout>
				<ExpertsController {...props} />
			</Layout>
		} />
		<Route exact path="/experts/create" render={props => 
			<Layout function="ExpertSubmit">
				<CreateExpertController {...props} />
			</Layout>
		} />
		<Route exact path="/experts/:expertId?" render={props => 
			<Layout>
				<ViewExpertController {...props} />
			</Layout>
		} />
		<Route exact path="/experts/edit/:expertId?" render={props => 
			<Layout>
				<EditExpertController {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/insights" render={props =>
			<Layout section="myCommunityInsights">
				<InsightsController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/insights/create" render={props => 
			<Layout section="myCommunityInsights" function="CommunityInsightsAdd">
				<CreateInsightController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/insights/:insightId?" render={props => 
			<Layout section="myCommunityInsights">
				<ViewInsightController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/my-community/insights/edit/:insightId?" render={props => 
			<Layout section="myCommunityInsights">
				<EditInsightController isCommunity {...props} />
			</Layout>
		} />
		<Route exact path="/business-profiles" render={props =>
			<Layout section="businessProfiles">
				<BusinessProfilesController {...props} />
			</Layout>
		} />
		<Route exact path="/business-profiles/create" render={props => 
			<Layout section="businessProfiles" function="BusinessProfileSubmit">
				<CreateBusinessProfileController {...props} />
			</Layout>
		} />
		<Route exact path="/business-profiles/edit" render={props => 
			<Layout section="businessProfiles" function="BusinessProfileEdit">
				<EditBusinessProfileController {...props} />
			</Layout>
		} />
		<Route exact path="/business-profiles/:profileId?" render={props => 
			<Layout section="businessProfiles">
				<ViewBusinessProfileController {...props} />
			</Layout>
		} />
		<Route exact path="/business-profiles/edit/:profileId?" render={props => 
			<Layout section="businessProfiles" function="BusinessProfileEdit">
				<EditBusinessProfileController {...props} />
			</Layout>
		} />
		<Route exact path="/resource-catalogue" render={props =>
			<Layout section="content">
				<ContentController {...props} />
			</Layout>
		} />
		<Route exact path="/resource-catalogue/create" render={props => 
			<Layout section="content" function="ContentSubmit">
				<CreateContentController {...props} />
			</Layout>
		} />
		<Route exact path="/resource-catalogue/:contentId?" render={props => 
			<Layout section="content">
				<ViewContentController {...props} />
			</Layout>
		} />
		<Route exact path="/resource-catalogue/edit/:contentId?" render={props => 
			<Layout section="content" function="ContentEdit">
				<EditContentController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations" render={props =>
			<Layout section="collaborations">
				<CollaborationsController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/create" render={props => 
			<Layout section="collaborations" function="CollaborationSubmit">
				<CreateCollaborationController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/enquiries" render={props => 
			<Layout section="collaborations" function="CollaborationEnquiryAdmin">
				<CollaborationEnquiriesController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/:collaborationId?" render={props => 
			<Layout section="collaborations">
				<ViewCollaborationController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/edit/:collaborationId?" render={props => 
			<Layout section="collaborations">
				<EditCollaborationController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/:collaborationId/discussion/:collaborationDiscussionId" render={props => 
			<Layout section="collaborations">
				<ViewCollaborationDiscussionController {...props} />
			</Layout>
		} />
		<Route exact path="/collaborations/:collaborationId/discussion/edit/:collaborationDiscussionId" render={props => 
			<Layout section="collaborations">
				<EditCollaborationDiscussionController {...props} />
			</Layout>
		} />
		<Route exact path="/admin/publish-admin">
			<Layout function="PublishingAdmin">
				<PublishAdmin />
			</Layout>
		</Route>
		<Route exact path="/microlearn" render={props =>
			<Layout section="microlearn">
				<MicroLearnController {...props} />
			</Layout>
		} />
		<Route exact path="/microlearn/create" render={props => 
			<Layout function="MicroLearnSubmit">
				<CreateMicroLearnController {...props} />
			</Layout>
		} />
		<Route exact path="/microlearn/:microLearnId?" render={props => 
			<Layout section="microlearn">
				<ViewMicroLearnController {...props} />
			</Layout>
		} />
		<Route exact path="/microlearn/edit/:microLearnId?" render={props => 
			<Layout section="microlearn">
				<EditMicroLearnController {...props} />
			</Layout>
		} />

		<UpgradeRoute path="/upgrade/collaboration" page="Collaboration" component={UpgradeCollaborationController} />
		<UpgradeRoute path="/upgrade/clo" page="CLO" component={UpgradeCloController} />
		<UpgradeRoute path="/upgrade/catalogues" page="Catalogues" component={UpgradeCataloguesController} />
		<UpgradeRoute path="/upgrade/offers" page="Offers" component={UpgradeOffersController} />
		<UpgradeRoute path="/upgrade/ip" page="IP" component={UpgradeIpController} />
		<UpgradeRoute path="/upgrade/team-skills" page="TeamSklls" component={UpgradeTeamSkillsController} />

		<Route exact path="/pricing" render={props => 
			<Layout section="upgrade">
				<PricingController 
					{...props}
				/>
			</Layout>
		} />

		<Route exact path="/aps/confirm-registration">
			<QuestionnaireLayout>
				<APS_Registration_Confirmation />
			</QuestionnaireLayout>
		</Route>

		<Route exact path="/landing" render={props => 
			<Landing {...props} />
		} />

		<Route exact path="/unauthorised" render={props => 
			<NotAuthorised {...props} />
		} />
		<Route>
			<NotFound />
		</Route>
	</Switch>
);