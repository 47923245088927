import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditStudentProject from './EditStudentProject';
import { showModal } from '../../store/modal';
import { getStudentProject, saveStudentProject, deleteStudentProject, isLoading } from '../../store/studentProjects';
import PageLayout from '../PageLayout';

const EditStudentProjectController = props => {
	const { projectId } = props.match.params;
	React.useEffect(() => props.getProject(projectId), [projectId]);

	return <PageLayout title="Edit Challenge" pageContent={
		<EditStudentProject
			project={props.project}
			saveProject={props.saveProject}
			deleteProject={props.deleteProject}
			loading={props.loading}
			primaryColour={props.primaryColour}
		/>
	} breadcrumbs={[{ label: "Business Challenges", path: "/business-challenges" }, "Edit Challenge"]} />;
};

EditStudentProjectController.propTypes = {
	project: PropTypes.object.isRequired,
	saveProject: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	saveResult: state.studentProjects.saveResult,
	project: state.studentProjects.project,
	loading: isLoading(state),
	primaryColour: state.theme.primaryColour
});

const mapDispatchToProps = dispatch => ({
	getProject: projectId => dispatch(getStudentProject(projectId)),
	saveProject: (project, newFiles) => dispatch(saveStudentProject(project, newFiles)),
	deleteProject: projectId => dispatch(showModal("CONFIRM_DELETE", {
		title: "Delete?",
		message: "Are you sure you want to delete this challenge?",
		onOk: () => dispatch(deleteStudentProject(projectId))
	}))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStudentProjectController);