import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMicroLearn, isLoading } from '../../store/microlearn';
import { canEditMicroLearn } from '../../store/security';
import PageLayout from '../PageLayout';
import ViewMicroLearn from './ViewMicroLearn';

const ViewMicroLearnController = props => {
	const microLearnId = Number(props.match.params.microLearnId);
	React.useEffect(() => props.getMicroLearn(microLearnId), [microLearnId]);

	return (
		<PageLayout 
			title="View MicroLearn" 
			pageStyle={{ fontSize: 'medium' }} 
			pageContent={
				<ViewMicroLearn 
					microLearn={props.microLearn} 
					loading={props.loading}
					canEdit={props.canEdit} 
					primaryColour={props.primaryColour} 
        		/>
			} 
			breadcrumbs={[
				{ label: "MicroLearn", path: `/microlearn` }, 
				"View MicroLearn"
			]} 
		/>
	);
};

ViewMicroLearnController.propTypes = {
	microLearn: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	primaryColour: PropTypes.string.isRequired
};

ViewMicroLearnController.defaultProps = {
	isCommunity: false
};

const mapStateToProps = (state, ownProps) => ({
	microLearn: state.microlearn.microLearn,
	loading: isLoading(state),
	canEdit: canEditMicroLearn(state),
	primaryColour: state.theme.primaryColour,
});

const mapDispatchToProps = dispatch => ({
	getMicroLearn: microLearnId => dispatch(getMicroLearn(microLearnId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMicroLearnController);